// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ToggleableFeaturesState } from '@memberspot/frontend/shared/util/feature-toggle';

import { Environment } from './environment-type';

export const environment: Environment = {
  envTag: 'dev',
  production: true,
  hideErrors: false,
  mixpanelToken: '4e54584831c7bcbab8afc1bdd5d6e20c',
  firebase: {
    apiKey: 'AIzaSyDD8ntvWSqgiP7eGIUP5OObnOPgBol7ZrQ',
    authDomain: 'auth2-dev.memberhub.de',
    databaseURL: 'https://coursehub-e5d21.firebaseio.com',
    projectId: 'coursehub-e5d21',
    storageBucket: 'coursehub-e5d21.appspot.com',
    messagingSenderId: '196416535181',
    appId: '1:196416535181:web:67e9106a0d0d2f556040c4',
    measurementId: 'G-PK1MM804H3',
  },
  firestoreLongPolling: false,
  buckets: {
    videos: 'gs://coursehub-e5d21-videos',
    videosEu: 'gs://coursehub-e5d21-videos-eu',
  },
  stripe: {
    apiKey: 'pk_test_ZAo4y2rHjLFCum1zoWFeiuwv00B9lKMpnI',
  },
  backend: 'https://coursehub-e5d21.appspot.com',
  clientUrl: 'mycoursehub.de',
  configFile: 'dev.config.json',
  thumbnailServiceUrl: 'https://service-thumbnail-dizxmo7htq-ew.a.run.app/api',
  copecartWebhookUrl:
    'https://europe-west1-coursehub-e5d21.cloudfunctions.net/copecart-copecartIpnEndpoint',
  digistoreWebhookUrl:
    'https://europe-west1-coursehub-e5d21.cloudfunctions.net/digistore-digistoreIpnEndpoint',
  zoomWebhookUrl:
    'https://europe-west1-coursehub-e5d21.cloudfunctions.net/zoom-zoomwebhook',
  ablefyWebhookUrl:
    'https://europe-west1-coursehub-e5d21.cloudfunctions.net/elopage-elopageWebhook',
  gtmId: 'GTM-P9J6WLL',
  hubspotClientId: '5e572a65-2371-443a-84a5-d2366f54e6d1',
  bitmovinAnalyticsToken: 'DD3953CA-9844-4B81-BAC4-2E39AAADA0A6',
  hubspot: {
    portalId: '19592273',
    formGUID: '813a5a27-82f7-4de1-b83f-69ce7eabfa79',
    subscriptionTypeId: 310076452,
  },
  zohoClientId: '',
  fileCdn: 'https://mspot-files-dev.b-cdn.net/',
  fontCdn: 'https://mspot-fonts.b-cdn.net/',
  googleFont: 'https://fonts.googleapis.com/css',
  featureToggles: <ToggleableFeaturesState>{
    memberLimitAlert: true,
    aiCta: true,
  },
  version: {
    actor: '86766276',
    build: '11816955376',
    env: 'dev',
    sha: '8787bb1c02eb90adae3554fb3ae02894ac6b08c0',
    tag: '',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
